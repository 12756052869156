<template>
	<!-- <div class="columns is-mobile is-multiline mt-4">
		<div
			class="column has-background-white is-12 pl-6 pr-6 st-card"
			:class="isCollapseOpen ? 'py-6' : 'py-1'"
		> -->
			<b-collapse :open.sync="isCollapseOpen" aria-id="simulationData" class="st-card has-background-white px-6">
				<template #trigger>
					<div class="columns is-mobile">
						<div class="is-align-self-center column is-8">
							<h1 class="is-title-2-m-d">{{headerTitle}}</h1>
						</div>
						<div v-if="isCollapse" class="column is-4">
							<b-button
								size="is-large"
								icon-pack="st"
								:icon-right="!isCollapseOpen ? 'arrow-down-circle' : 'arrow-up-circle'"
								class="no-focus float-right"
								type="is-ghost"
								aria-controls="simulationData"
							/>
						</div>
					</div>
				</template>
				<!--<h1
					class="is-headline-m has-text-blue mb-4 mt-3"
				>{{$t("components.Data.Auto.vehicle")}}</h1>-->
				<div class="columns is-mobile is-multiline">
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.name")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.name}}</p>
					</div>
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.surname")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.surname}}</p>
					</div>
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.email")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.email}}</p>
					</div>
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.birthdate")}}</p>
						<p class="is-headline-m has-text-black">{{dateToLocale(simulation.data.birthdate)}}</p>
					</div>
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.nif")}}</p>
						<p class="is-headline-m has-text-black is-uppercase">{{simulation.data.nif}}</p>
					</div>
					<div class="column is-6-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.phone")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.phone_number}}</p>
					</div>
				</div>
				<h1
					class="is-headline-m has-text-blue mb-4 mt-5"
					v-show="simulation.data.persons_data.length > 0"
				>{{$t("components.Data.Health.persons")}}</h1>
				<div v-for="(person, i) in simulation.data.persons_data" :key="i" class="columns is-mobile is-multiline">
					<div class="column is-6">
						<p class="is-subhead-m has-text-gray">{{$t("fields.birthdate")}}</p>
						<p class="is-headline-m has-text-black">{{dateToLocale(person.birthdate)}}</p>
					</div>
					<div class="column is-6">
						<p class="is-subhead-m has-text-gray">{{$t("fields.relationship")}}</p>
						<p class="is-headline-m has-text-black">{{person.relationship}}</p>
					</div>
				</div>
				<h1
					class="is-headline-m has-text-blue mb-4 mt-5"
				>{{$t("fields.payment_type")}}</h1>
				<div class="columns is-mobile is-multiline">
					<div class="column is-4-desktop is-6-mobile">
						<p
							class="is-headline-m has-text-black"
						>{{$t('payment_type')[simulation.data.payment_type]}}</p>
					</div>
				</div>
			</b-collapse>
		<!-- </div>
	</div> -->
</template>

<script>
	export default {
		name: "TeethData",
		props: {
			header: {
				type: String,
				default: null
			},
			simulation: {
				type: Object,
				default: null,
			},
			isCollapse: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				headerTitle: null,
				isCollapseOpen: false,
			};
		},
		created() {
			this.headerTitle = this.header;
			if(!this.headerTitle) this.headerTitle = this.$t("components.Data.simulationData");
			if (!this.isCollapse) this.isCollapseOpen = true;
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	
</style>