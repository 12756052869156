import { render, staticRenderFns } from "./CompanyLife.vue?vue&type=template&id=7c4f84be&scoped=true&"
import script from "./CompanyLife.vue?vue&type=script&lang=js&"
export * from "./CompanyLife.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c4f84be",
  null
  
)

export default component.exports