<template>
	<div class="message-item" :class="isIncoming ? 'incoming-message' : 'outgoing-message'">
		<div class="message-avatar mb-2">
			<figure class="image is-32x32">
				<img :src="user.picture || getDefaultImg" @error="setDefaultImg" alt class="is-rounded" />
			</figure>
			<div class="ml-4">
				<h5 class="is-body-m">{{user.name}} {{user.surname}}</h5>
				<div class="is-caption-m has-text-gray-darker">{{moment(message.createdAt).format('LT')}}</div>
			</div>
		</div>
		<div
			v-if="message.type == 'message' || !message.type"
			class="message-content py-2 px-4"
		>{{message.message}}</div>
		<div
			v-else-if="imgExtension.includes(message.data.extension.toLowerCase())"
			class="message-content py-2 px-4"
		>
			<img :src="message.message" @load="$emit('scroll')" />
		</div>
		<div v-else class="message-content py-2 px-4">
			<a
				:href="message.message"
				:class="{'has-text-white': !isIncoming, 'has-text-black': isIncoming}"
				target="_blank"
			>
				{{message.data.name}}
				<b-icon pack="st" class="ml-3" icon="download"></b-icon>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ChatMessage",
		props: {
			user: { type: Object, default: null },
			message: { type: Object, default: null },
		},
		created() {
			if (this.user._id == this.getUser._id) this.isIncoming = false;
		},
		data() {
			return {
				isIncoming: true,
				imgExtension: [
					".jpg",
					".jpeg",
					".gif",
					".png",
					".svg",
					".web",
					".tiff",
				],
			};
		},
		methods: {
			show() {
				console.log("oi");
			},
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.message-item {
		margin-top: 1rem;
	}

	.message-content {
		border-radius: 0.5rem;
		word-break: break-word;
		@include from($desktop) {
			max-width: 55vw;
		}
	}

	.outgoing-message {
		margin-left: auto;
		.message-content {
			background-color: $blue;
			color: white;
			float: right;
		}
		.message-avatar {
			justify-content: flex-end;
		}
	}
	.incoming-message {
		margin-right: auto;
		.message-content {
			background-color: $white;
			color: $black;
			margin-right: auto;
		}
		.message-avatar {
			justify-content: flex-start;
		}
	}
	.message-avatar {
		display: flex;
	}

	a:hover {
		text-decoration: underline;
	}
</style>