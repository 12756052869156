<template>
	<!-- <div class="columns is-mobile is-multiline mt-4">
		<div
			class="column has-background-white is-12 pl-6 pr-6 st-card"
			:class="isCollapseOpen ? 'py-6' : 'py-1'"
		> -->
			<b-collapse :open.sync="isCollapseOpen" aria-id="simulationData" class="st-card has-background-white px-6">
				<template #trigger>
					<div class="columns is-mobile">
						<div class="is-align-self-center column is-8">
							<h1 class="is-title-2-m-d">{{headerTitle}}</h1>
						</div>
						<div v-if="isCollapse" class="column is-4">
							<b-button
								size="is-large"
								icon-pack="st"
								:icon-right="!isCollapseOpen ? 'arrow-down-circle' : 'arrow-up-circle'"
								class="no-focus float-right"
								type="is-ghost"
								aria-controls="simulationData"
							/>
						</div>
					</div>
				</template>
				<!--<h1
					class="is-headline-m has-text-blue mb-4 mt-3"
				>{{$t("components.Data.Auto.vehicle")}}</h1>-->
				<div class="columns is-mobile is-multiline">
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.name")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.name}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.birthdate")}}</p>
						<p class="is-headline-m has-text-black">{{dateToLocale(simulation.data.birthdate)}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.nif")}}</p>
						<p class="is-headline-m has-text-black is-uppercase">{{simulation.data.nif}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.address")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.address}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.postal_code")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.postal_code}}</p>
					</div>
				</div>
				<h1
					class="is-headline-m has-text-blue mb-4 mt-3"
				>{{$t("components.Data.House.details")}}</h1>
				<div class="columns is-mobile is-multiline">
					<div class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.construction_year")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.construction_year}}</p>
					</div>
					<div class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.habitation_type")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.habitation_type}}</p>
					</div>
					<div v-if="simulation.data.habitation_typology" class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.typology")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.habitation_typology}}</p>
					</div>
					<div v-if="simulation.data.habitation_floor" class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.habitation_floor")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.habitation_floor}}</p>
					</div>
					<div class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.house_value")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.house_value}}</p>
					</div>
					<div class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.house_filling")}}</p>
						<p class="is-headline-m has-text-black">{{getTrueFalse(simulation.data.house_filling).label}}</p>
					</div>
					<div v-if="simulation.data.habitation_floor" class="column is-4-desktop is-6-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.house_filling_value")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.house_filling_value}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.address")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.house_address}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.postal_code")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.house_postal_code}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.size")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.size}}</p>
					</div>
					<div v-if="simulation.data.car_value" class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.car_value")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.car_value}}</p>
					</div>
					<div v-if="simulation.data.informatic_value" class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.informatic_value")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.informatic_value}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.wc_quantity")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.wc_quantity}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.rooms_number")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.rooms_number}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.canalization")}}</p>
						<p class="is-headline-m has-text-black">{{getTrueFalse(simulation.data.canalization).label}}</p>
					</div>
					<div v-if="simulation.data.canalization" class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("fields.canalization_replacement")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.canalization_replacement}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.ownership")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.ownership}}</p>
					</div>
					<div class="column is-4-desktop is-12-mobile">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.house_use")}}</p>
						<p class="is-headline-m has-text-black">{{simulation.data.house_use}}</p>
					</div>
					<div class="column is-12">
						<p class="is-subhead-m has-text-gray">{{$t("components.Data.House.security")}}</p>
						<p v-for="(sec, i) in simulation.data.security" :key="200+i" class="is-headline-m has-text-black">{{sec}}</p>
					</div>
				</div>
				<h1
					class="is-headline-m has-text-blue mb-4 mt-5"
				>{{$t("components.Data.House.coverages")}}</h1>
				<div class="columns is-mobile is-multiline">
					<div  v-for="(cover,i) in simulation.data.coverages" :key="i" class="column is-4-desktop is-6-mobile">
						<p
							class="is-headline-m has-text-black"
						>{{cover}}</p>
					</div>
				</div>
				<h1
					class="is-headline-m has-text-blue mb-4 mt-5"
				>{{$t("fields.payment_type")}}</h1>
				<div class="columns is-mobile is-multiline">
					<div class="column is-4-desktop is-6-mobile">
						<p
							class="is-headline-m has-text-black"
						>{{$t('payment_type')[simulation.data.payment_type]}}</p>
					</div>
				</div>
			</b-collapse>
		<!-- </div>
	</div> -->
</template>

<script>
	export default {
		name: "LifeData",
		props: {
			header: {
				type: String,
				default: null
			},
			simulation: {
				type: Object,
				default: null,
			},
			isCollapse: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				headerTitle: null,
				isCollapseOpen: false,
			};
		},
		created() {
			this.headerTitle = this.header;
			if(!this.headerTitle) this.headerTitle = this.$t("components.Data.simulationData");
			if (!this.isCollapse) this.isCollapseOpen = true;
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	
</style>