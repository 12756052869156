<template>
	<b-menu-item class="chat-user" @click="onClick" :class="{'has-background-gray-light': selected}">
		<template #label="props">
			<figure class="image is-32x32">
				<img :src="room.picture || getDefaultImg" @error="setDefaultImg" class="is-rounded" />
			</figure>
			<div class="list-body" :class="customClass">
				<div class="chat-user-message">
					<h5>{{room.name}}</h5>
					<p v-if="room.lastMessage">
						<span v-if="room.lastMessage.user == getUser._id">{{t.you}}:</span>
						{{room.lastMessage.type == 'message' || !room.lastMessage.type ? room.lastMessage.message : (room.lastMessage.user == getUser._id ? t.youSentFile : t.theySentFile)}}
					</p>
				</div>
				<div class="chat-info">
					<div class="new-message-count">{{room.unread}}</div>
					<small v-if="room.lastMessage">{{moment(room.lastMessage.createdAt).format('LT')}}</small>
				</div>
			</div>
		</template>
	</b-menu-item>
</template>

<script>
	export default {
		name: "ChatUser",
		props: {
			selected: {
				type: Boolean,
				default: false,
			},
			unread: {
				type: Number,
				default: 0,
			},
			room: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				t: this.$t("components.Chat.User"),
				customClass: "",
			};
		},
		watch: {
			"$route.params.id"() {
				this.setClasses();
			},
			"room.unread"() {
				this.setClasses();
			},
		},
		created() {
			this.setClasses();
		},
		methods: {
			onClick() {
				return this.$emit("click", this.room._id);
			},
			setClasses() {
				this.customClass = "";

				if (this.room.unread > 0) this.customClass = "message-unseen";
				if (this.$route.params.id == this.room._id)
					this.customClass = "message-selected";
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/variables/all";

	.chat-user {
		display: flex;

		padding-top: 1rem;
		padding-bottom: 1rem;

		figure {
			margin-right: 1rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		h5 {
			font-size: 1rem;
		}
		p {
			font-size: 0.75rem;
		}

		.chat-info {
			display: unset;
		}

		.list-body {
			flex: 1 1;
			position: relative;
			min-width: 0;
			display: flex;
		}

		.chat-user-message {
			min-width: 0;
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-moz-box-orient: vertical;
			-moz-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-flex: 1;
			-webkit-flex: 1;
			-moz-box-flex: 1;
			-ms-flex: 1;
			flex: 1;

			h5 {
				white-space: nowrap;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 0.2rem;
				color: $black;
			}

			p {
				white-space: nowrap;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-bottom: 0;
				color: $gray-darker;
			}
		}

		.new-message-count {
			display: none;
			width: 23px;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-moz-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-moz-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			line-height: 0;
			font-size: 0.8rem;
			height: 23px;
			background-color: $blue;
			color: white;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			margin-left: auto;
		}

		small {
			font-size: 0.79rem;
			color: $gray-darker;
		}

		.message-unseen {
			.chat-user-message {
				h5 {
					color: $blue;
				}
			}
			.new-message-count {
				display: -webkit-box;
				display: -webkit-flex;
				display: -moz-box;
				display: -ms-flexbox;
				display: flex;
			}
			small {
				color: $blue !important;
			}
		}

		.message-selected {
			.chat-user-message {
				p {
					font-weight: bold;
				}
			}
		}
	}
</style>