<template>
	<div class="message-item messages-divider pt-4">
		<span>{{text}}</span>
	</div>
</template>

<script>
	export default {
		name: "ChatDivider",
		props: {
			text: { type: String, default: "" },
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';

	.message-item {
		width: 100%;
		text-align: center;
		span {
			font-size: 0.8rem;
			background-color: $white;
			padding: 0.4rem 0.8rem;
			border-radius: 0.4rem;
		}
	}
</style>