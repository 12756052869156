import {
    get,
    post,
    put
} from '@/utils/http';
const queryString = require("query-string");

export function getSimulations(query = {}) {
    let _query = "?" + queryString.stringify(query)
    return get('/segtrade/simulations'+ _query);
}

export function getSimulationById(id) {
    return get('/segtrade/simulations/' + id);
}

export function getSimulationByIdAndProposals(id) {
    return get(`/segtrade/simulations/${id}/proposals`);
}

export function getLicensePlate(lp) {
    return get(`/regcheck/${lp}`);
}

export function addSimulation(type, data) {
    return post(`/segtrade/simulations/${type}`, data)
}

export function updateSimulation(id) {
    return put(`/segtrade/simulations/${id}`);
}

export function updateSimulationNewProposals(id) {
    return put(`/segtrade/simulations/${id}/proposals`);
}