import { render, staticRenderFns } from "./Weapons.vue?vue&type=template&id=e52f3658&scoped=true&"
import script from "./Weapons.vue?vue&type=script&lang=js&"
export * from "./Weapons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e52f3658",
  null
  
)

export default component.exports