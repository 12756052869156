<template>
	<div class="modal is-active">
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">{{t.uploadFiles}}</p>
				<button class="delete" aria-label="close" @click="closeThis"></button>
			</header>
			<section class="modal-card-body has-text-centered">
				<b-upload class="mb-8" v-model="droppedFiles" multiple drag-drop>
					<section class="section">
						<div class="content has-text-centered">
							<p>
								<b-icon icon="upload" size="is-large"></b-icon>
							</p>
							<p>{{t.dropHere}}</p>
						</div>
					</section>
				</b-upload>
				<div v-if="droppedFilesCopy.length > 0">
					<table>
						<thead>
							<tr>
								<th class="pr-4 pb-2">Ficheiros</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(file, i) in droppedFilesCopy" :key="i">
								<td class="pr-4">{{file.file.name}}</td>
								<td>
									<b-button v-if="file.status == 'waiting'" :loading="isLoading" @click="removeFile(i)" size="is-small">
										<b-icon pack="st" icon="close"></b-icon>
									</b-button>
									<b-button v-else size="is-small" type="is-green">
										<b-icon pack="st" icon="tick" size="is-small"></b-icon>
									</b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</section>
			<footer class="modal-card-foot">
				<div>
					<b-button @click="closeThis" :loading="isLoading" class="cancel" type="is-black">{{$t("shared.cancel")}}</b-button>
					<b-button @click="sendFiles" :disabled="droppedFilesCopy.length <= 0" :loading="isLoading" class="submit" type="is-primary">{{t.upload}}</b-button>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ChatUpload",
		props: {
			roomID: { type: String, default: null },
		},
		data() {
			return {
				isLoading: false,
				t: this.$t("components.Chat.Upload"),
				droppedFiles: [],
				droppedFilesCopy: [],
			};
		},
		watch: {
			droppedFiles(_old, _new) {
				this.droppedFilesCopy = [];
				for (let file of _new) {
					this.droppedFilesCopy.push({ file, status: "waiting" });
				}
			},
		},
		methods: {
			closeThis() {
				return this.$emit("close");
			},
			sendFiles() {
				this.isLoading = true;
				this.$socket.emit(
					"chat_send_file_for_room",
					this.getToken,
					this.roomID,
					this.getFiles
				);
			},
			removeFile(i) {
				this.droppedFiles.splice(i, 1);
			},
		},
		computed: {
			getFiles() {
				let files = [];
				let i = 0;
				for (let file of this.droppedFiles) {
					files.push({ name: file.name, file, index: i });
					i++;
				}
				return files;
			},
		},
		sockets: {
			chat_file_sent(i) {
				this.droppedFilesCopy[i].status = "uploaded";
				let allUploaded = this.droppedFilesCopy.every(i => i.status == "uploaded")
				if(allUploaded) {
					this.isLoading = false;
					this.closeThis();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.modal-card {
		// width: auto;
	}
	.modal-card-foot {
		div {
			width: 100%;
			.cancel {
				float: left;
			}
			.submit {
				float: right;
			}
		}
	}

	table {
		margin: auto;
	}

	tbody {
		tr {
			height: 45px;
			> td {
				vertical-align: middle;
			}
		}
	}
</style>