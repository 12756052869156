<template>
  <div class="" style="margin-top: 0.8rem;">
    <b-tabs
      id="settings-tab"
      position="is-centered"
      type="is-boxed"
      expanded
      v-model="activeTab"
    >
      <b-tab-item
        class="st-tab-item"
        id="profile"
        value="profile"
        :label="t.title"
      >
        <form
          @submit.prevent="formSubmit"
          class="columns is-mobile is-multiline p-2"
        >
          <div class="column is-6-touch is-4-desktop is-offset-1-desktop">
            <b-field
              :type="errors.includes('name') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.name')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                required
                autocomplete="on"
                name="given-name"
                v-model="form.name"
                :placeholder="$t('fields.name')"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6-touch is-4-desktop">
            <b-field
              :type="errors.includes('surname') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.surname')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                required
                autocomplete="on"
                name="family-name"
                v-model="form.surname"
                :placeholder="$t('fields.surname')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="mediator"
            class="column is-12-touch is-offset-1 is-8-desktop"
          >
            <b-field
              :type="errors.includes('business_name') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.business_name')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                required
                autocomplete="on"
                name="family-name"
                v-model="form.business_name"
                :placeholder="$t('fields.business_name')"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12-touch is-offset-1-desktop is-8-desktop">
            <b-field
              :type="errors.includes('email') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.email')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="email"
                type="email"
                v-model="form.email"
                disabled
                :placeholder="$t('fields.email')"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12-touch is-offset-1-desktop is-4-desktop">
            <b-field
              :type="errors.includes('birthdate') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.birthdate')"
            >
              <b-datepicker
                v-model="form.birthdate"
                :placeholder="$t('fields.birthdate')"
                custom-class="p-0 h-6"
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                :mobile-native="false"
                :max-date="new Date()"
                expanded
              ></b-datepicker>
            </b-field>
          </div>
          <div
            class="column is-12-touch is-4-desktop is-caption-m has-text-black"
          >
            <b-field
              :type="errors.includes('gender') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.gender')"
            >
              <b-select
                class="p-0 h-6 is-headline-m st-line-input has-text-black profile"
                outlined
                expanded
                v-model="form.gender"
              >
                <option
                  v-for="(gender, i) in $t('genders')"
                  :key="i"
                  :value="gender.value"
                >
                  {{ gender.text }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div
            class="
                  column
                  is-12-touch is-offset-1-desktop is-8-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('phone') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.phone')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="phone"
                type="tel"
                v-model="form.phone"
                :placeholder="$t('fields.phone')"
              ></b-input>
            </b-field>
          </div>
          <div
            class="
                  column
                  is-12-touch is-offset-1-desktop is-4-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('address') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.address')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="address"
                type="text"
                v-model="form.address"
                :placeholder="$t('fields.address')"
              ></b-input>
            </b-field>
          </div>
          <div
            class="column is-12-touch is-4-desktop is-caption-m has-text-black"
          >
            <b-field
              :type="errors.includes('postal_code') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.postal_code')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="postal_code"
                type="text"
                v-model="form.postal_code"
                :placeholder="$t('fields.postal_code')"
              ></b-input>
            </b-field>
          </div>
          <div
            class="
                  column
                  is-12-touch is-offset-1-desktop is-4-desktop is-caption-m
                  has-text-black
                "
            v-if="!mediator"
          >
            <b-field
              :type="errors.includes('civil_status') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.civil_status')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="civil_status"
                type="text"
                v-model="form.civil_status"
                :placeholder="$t('fields.civil_status')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="!mediator"
            class="column is-12-touch is-4-desktop is-caption-m has-text-black"
          >
            <b-field
              :type="errors.includes('childrens_number') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.childrens_number')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="childrens_number"
                type="number"
                v-model="form.childrens_number"
                :placeholder="$t('fields.childrens_number')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="!mediator"
            class="
                  column
                  is-12-touch is-offset-1-desktop is-8-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('profession') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.profession')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="profession"
                type="text"
                v-model="form.profession"
                :placeholder="$t('fields.profession')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="!mediator"
            class="
                  column
                  is-12-touch is-offset-1-desktop is-4-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('id_card') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.id_card')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="id_card"
                type="text"
                minlength="7"
                v-model="form.id_card"
                :placeholder="$t('fields.id_card')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-else
            class="
                  column
                  is-12-touch is-offset-1-desktop is-4-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('asf_number') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.asf_number')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="asf_number"
                type="text"
                v-model="form.asf_number"
                :placeholder="$t('fields.asf_number')"
              ></b-input>
            </b-field>
          </div>
          <div
            class="column is-12-touch is-4-desktop is-caption-m has-text-black"
          >
            <b-field
              :type="invalidNif ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.nif')"
            >
              <b-input
                outlined
                type="text"
                maxlength="9"
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                @input="onNifInput"
                v-model="form.nif"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="!mediator"
            class="
                  column
                  is-12-touch is-offset-1-desktop is-4-desktop is-caption-m
                  has-text-black
                "
          >
            <b-field
              :type="errors.includes('drive_license') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.drive_license')"
            >
              <b-input
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                outlined
                autocomplete="on"
                name="drive_license"
                type="text"
                v-model="form.drive_license"
                :placeholder="$t('fields.drive_license')"
              ></b-input>
            </b-field>
          </div>
          <div
            v-if="!mediator"
            class="column is-12-touch is-4-desktop is-caption-m has-text-black"
          >
            <b-field
              :type="errors.includes('drive_license_date') ? 'is-error' : ''"
              class="is-caption-m has-text-black"
              :label="$t('fields.drive_license_date')"
            >
              <b-datepicker
                v-model="form.drive_license_date"
                :placeholder="$t('fields.drive_license_date')"
                custom-class="p-0"
                class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
                position="is-top-right"
                :mobile-native="false"
                :max-date="new Date()"
                expanded
              ></b-datepicker>
            </b-field>
          </div>
          <div
            class="column is-12-touch is-4-desktop is-caption-m has-text-black is-offset-1-desktop"
          >
            <b-field
              class="file is-primary"
              :class="{ 'has-name': !!file }"
            >
              <b-upload v-model="file" class="file-label">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">{{ t.fileUpload }}</span>
                </span>
                <span class="file-name" v-if="file">
                  {{ file.name }}
                </span>
              </b-upload>
            </b-field>
          </div>
          <div class="column is-12-touch is-offset-1-desktop is-8-desktop">
            <b-button
              expanded
              class="is-headline-bold-d-d is-headline-m-m"
              type="is-black"
              native-type="submit"
              >{{ t.btn }}</b-button
            >
          </div>
        </form>
      </b-tab-item>
      <b-tab-item
        class="st-tab-item"
        id="password"
        value="password"
        :label="t.change_password"
      >
        <form
          @submit.prevent="formSubmitPassword"
          class="columns is-mobile is-multiline p-2 mt-4"
        >
          <div class="column is-offset-1-desktop is-8-desktop is-12-touch">
            <b-input
              class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
              outlined
              icon-pack="st"
              autocomplete="new-password"
              name="new-password"
              icon="password"
              type="password"
              minlength="8"
              v-model="form_password.password"
              required
              password-reveal
              :placeholder="$t('fields.new_password')"
            ></b-input>
          </div>
          <div class="column is-12-touch is-offset-1-desktop is-8-desktop">
            <b-button
              expanded
              class="is-headline-bold-d-d is-headline-m-m"
              type="is-black"
              native-type="submit"
              >{{ t.btn }}</b-button
            >
          </div>
        </form>
      </b-tab-item>
    </b-tabs>
    <!--<Modal />-->
  </div>
</template>

<script>
//import Modal from '@/components/Shared/Modal.vue';
import { updateUser, updatePassword, updatePicture } from "@/api/users";
import { validateNIF } from "@/utils/utils";

export default {
  name: "Settings",
  components: {},
  data() {
    return {
      t: this.$t("views.Profile"),
      activeTab: 0,
      form: {
        name: null,
        surname: null,
        gender: null,
        birthdate: null,
        phone: null,
        address: null,
        postal_code: null,
        profession: null,
        civil_status: null,
        childrens_number: null,
        nif: null,
        id_card: null,
        drive_license: null,
        drive_license_date: null,
      },
      form_password: {
        password: null,
      },
      invalidNif: false,
      mediator: false,
      errors: [],
      file: null,
    };
  },
  created() {
    if (this.getUser.user_type == "mediator") {
      this.mediator = true;
      this.form.business_name = this.getUser.business_name;
      this.form.asf_number = this.getUser.asf_number;
    }
    this.form.email = this.getUser.email;
    this.form.name = this.getUser.name;
    this.form.surname = this.getUser.surname;
    if (this.getUser.birthdate)
      this.form.birthdate = new Date(this.getUser.birthdate);
    this.form.gender = this.getUser.gender;
    this.form.nif = this.getUser.nif;
    this.form.phone = this.getUser.phone;
    this.form.address = this.getUser.address;
    if (this.getUser.postal_code.number && this.getUser.postal_code.ext)
      this.form.postal_code =
        this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext;
    this.form.profession = this.getUser.profession;
    this.form.civil_status = this.getUser.civil_status;
    this.form.childrens_number = this.getUser.childrens_number;
    this.form.id_card = this.getUser.id_card;
    this.form.drive_license = this.getUser.drive_license;
    if (this.getUser.drive_license_date) {
      this.form.drive_license_date = new Date(this.getUser.drive_license_date);
    }
  },
  methods: {
    formSubmit() {
      this.errors = [];
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] == "") this.form[key] = null;
      });
      if (this.file) {
        updatePicture(this.getUser._id, this.file)
          .then(() => {
            this.file = null;
            updateUser(this.form)
              .then((response) => {
                this.$store.dispatch("auth/authenticateUser", response, {
                  root: false,
                });
                this.$emit("user-update");
                this.$buefy.toast.open({
                  message: this.$t("views.Profile.updateUser.success"),
                  position: "is-bottom",
                  type: "is-primary",
                });
              })
              .catch((error) => {
                console.error(error);
                error.response.data.forEach((element) => {
                  if (element.param == "nif") this.invalidNif = true;
                  else this.errors.push(element.param);
                });
                this.$buefy.toast.open({
                  message: this.$t("views.Profile.updateUser.error"),
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
        });
      } else {
        updateUser(this.form)
        .then((response) => {
          this.$store.dispatch("auth/authenticateUser", response, {
            root: false,
          });
          this.$emit("user-update");
          this.$buefy.toast.open({
            message: this.$t("views.Profile.updateUser.success"),
            position: "is-bottom",
            type: "is-primary",
          });
        })
        .catch((error) => {
          console.error(error);
          error.response.data.forEach(element => {
            if(element.param =="nif") this.invalidNif = true;
            else this.errors.push(element.param);
          });
          this.$buefy.toast.open({
            message: this.$t("views.Profile.updateUser.error"),
            position: "is-bottom",
            type: "is-danger",
          });
        });
      }
    },
    formSubmitPassword() {
      updatePassword(this.form_password)
        .then((response) => {
          this.form_password.password = null;
          this.activeTab = 0;
          this.$buefy.toast.open({
            message: this.$t("views.Profile.updateUser.success"),
            position: "is-bottom",
            type: "is-primary",
          });
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: this.$t("views.Profile.updateUser.error"),
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    onNifInput() {
      if (this.form.nif.length == 9)
        this.invalidNif = !validateNIF(this.form.person.nif);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/all";
@import "~bulma/sass/utilities/mixins.sass";
.first-div {
  > .columns:nth-of-type(1) {
    padding-top: $space/2;
    padding-bottom: $space * 1.5;
  }
  @include desktop() {
    background-color: $gray-light;
  }
  // padding-bottom: 10vh;
  min-height: 100vh;

  div:nth-of-type(1) {
  }
}
.select:not(.is-multiple) {
  height: 1.5em;
}
</style>
<style>
#settings-tab nav ul li a {
  background-color: #fff;
  padding: 2rem 0px;
}
#settings-tab .tabs.is-boxed li.is-active a {
  background-color: transparent !important;
  border-color: #dbdbdb;
  border-bottom-color: transparent !important;
  color: #4a4a4a;
}
.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: none;
}
.input[disabled] {
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid;
  border-bottom-color: inherit;
  box-shadow: none;
  color: inherit;
}
</style>
