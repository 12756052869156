import {
    get,
    post,
    formData,
    formDataPut,
    put
} from '@/utils/http';

const queryString = require("query-string");

export function createProposal(documents, data) {
    let form = new FormData()
    for (let i = 0; i < documents.length; i++) {
        let file = documents[i];

        form.append('documents', file);
    }
    form.append('data', JSON.stringify(data));
    return formData('/segtrade/proposals', form)
}

export function getProposals(query = {}) {
    let _query = "?" + queryString.stringify(query)
    return get('/segtrade/proposals' + _query);
}

export function getProposalById(id) {
    return get('/segtrade/proposals/' + id);
}

export function getAnalytics() {
    return get('/segtrade/proposals/analytics');
}

export function acceptProposal(id, payment_split) {
    return put(`/segtrade/proposals/${id}`, {
        "proposal_accept": true,
        "type_payment": payment_split
    })
}

export function putDocuments(id, documents) {
    let form = new FormData()
    for (let i = 0; i < documents.length; i++) {
        let file = documents[i];

        form.append('documents', file);
    }

    return formDataPut('/segtrade/proposals/' + id + '/documents', form);
}