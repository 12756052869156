export function validateNIF(value) {
    if (!value || typeof value !== "string" || value.length !== 9) return false;

    let sumAux = 0;
    for (let i = 9; i >= 2; i--) {
        sumAux += i * (parseInt(value[value.length - i]) || 0);
    }

    const module = sumAux % 11;

    // Get the eight first numbers
    const NIFwithoutLastDigit = value.slice(0, value.length - 1);

    if (module === 0 || module === 1) {
        return `${NIFwithoutLastDigit}0` === value;
    } else {
        return `${NIFwithoutLastDigit}${11 - module}` === value;
    }
}

export function validateLicensePlate(value) {
    let regex = /^(([A-Z]{2}-\d{2}-(\d{2}|[A-Z]{2}))|(\d{2}-(\d{2}-[A-Z]{2}|[A-Z]{2}-\d{2})))$/;

    let test = regex.test(value);
    if (!test) {
        value = transformLicensePlate(value);
        test = regex.test(value);
    }

    return test
}

export function transformLicensePlate(value) {
    let plate = value.match(/.{1,2}/g);
    return `${plate[0]}-${plate[1]}-${plate[2]}`.toUpperCase();
}